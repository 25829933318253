import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loader-overlay"
}
const _hoisted_2 = { class: "app-content" }
const _hoisted_3 = { class: "container-fluid" }
const _hoisted_4 = { class: "row g-4" }
const _hoisted_5 = {
  key: 0,
  class: "col-12"
}
const _hoisted_6 = { class: "callout callout-info" }
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = { class: "card card-danger card-outline mb-4" }
const _hoisted_9 = { class: "card-body" }
const _hoisted_10 = { class: "navbar navbar-expand-lg bg-body-tertiary" }
const _hoisted_11 = {
  key: 1,
  class: "col-md-12"
}
const _hoisted_12 = { class: "card card-info card-outline mb-4" }
const _hoisted_13 = { class: "card-header" }
const _hoisted_14 = { class: "card-title" }
const _hoisted_15 = { class: "card-body" }
const _hoisted_16 = { class: "row g-3" }
const _hoisted_17 = { class: "col-md-9" }
const _hoisted_18 = { class: "row g-3" }
const _hoisted_19 = { class: "col-md-4" }
const _hoisted_20 = ["value"]
const _hoisted_21 = { class: "col-md-4" }
const _hoisted_22 = ["value"]
const _hoisted_23 = { class: "col-md-12" }
const _hoisted_24 = {
  class: "form-control",
  disabled: "",
  rows: "20"
}
const _hoisted_25 = { class: "col-md-3" }
const _hoisted_26 = ["href"]
const _hoisted_27 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[2] || (_cache[2] = [
          _createElementVNode("div", { class: "loader" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    _cache[8] || (_cache[8] = _createStaticVNode("<div class=\"app-content-header\"><div class=\"container-fluid\"><div class=\"row\"><div class=\"col-sm-6\"><h3 class=\"mb-0\">Products</h3></div></div></div></div>", 1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.errorMessage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.errorMessage), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "card-header" }, [
                _createElementVNode("div", { class: "card-title" }, "Search products by UPC or name")
              ], -1)),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("nav", _hoisted_10, [
                  _createElementVNode("form", {
                    class: "d-flex",
                    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
                  }, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
                      class: "form-control me-2",
                      placeholder: "Search"
                    }, null, 512), [
                      [_vModelText, _ctx.searchQuery]
                    ]),
                    _cache[3] || (_cache[3] = _createElementVNode("button", {
                      class: "btn btn-success",
                      type: "submit"
                    }, "Search", -1))
                  ], 32)
                ])
              ])
            ])
          ]),
          (_ctx.products.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: product.id
                  }, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, _toDisplayString(product.name), 1)
                      ]),
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, [
                          _createElementVNode("div", _hoisted_17, [
                            _createElementVNode("div", _hoisted_18, [
                              _createElementVNode("div", _hoisted_19, [
                                _cache[5] || (_cache[5] = _createElementVNode("label", { class: "form-label" }, "ID", -1)),
                                _createElementVNode("input", {
                                  type: "text",
                                  class: "form-control",
                                  value: product.id,
                                  disabled: ""
                                }, null, 8, _hoisted_20)
                              ]),
                              _createElementVNode("div", _hoisted_21, [
                                _cache[6] || (_cache[6] = _createElementVNode("label", { class: "form-label" }, "Size", -1)),
                                _createElementVNode("input", {
                                  type: "text",
                                  class: "form-control",
                                  value: product.size,
                                  disabled: ""
                                }, null, 8, _hoisted_22)
                              ]),
                              _createElementVNode("div", _hoisted_23, [
                                _cache[7] || (_cache[7] = _createElementVNode("label", { class: "form-label" }, "Description", -1)),
                                _createElementVNode("textarea", _hoisted_24, _toDisplayString(product.description), 1)
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_25, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(product.imageUrls, (imageUrl, index) => {
                              return (_openBlock(), _createElementBlock("div", { key: index }, [
                                _createElementVNode("a", {
                                  href: imageUrl,
                                  target: "_blank",
                                  rel: "noopener noreferrer"
                                }, [
                                  _createElementVNode("img", {
                                    src: imageUrl,
                                    alt: "Product Image",
                                    style: {"max-width":"200px","margin-bottom":"10px"}
                                  }, null, 8, _hoisted_27)
                                ], 8, _hoisted_26)
                              ]))
                            }), 128))
                          ])
                        ])
                      ])
                    ])
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 64))
}