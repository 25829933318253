export const ORDER_STATUSES = {
  ORDER_STATUS_NEW: 0,
  ORDER_STATUS_PAYMENT_SUCCESSFUL: 1,
  ORDER_STATUS_IN_DELIVERY: 10,
  ORDER_STATUS_DELIVERED: 100,
  ORDER_STATUS_READY_TO_PICK_UP: 11,
  ORDER_STATUS_PICKED_UP: 101,
  ORDER_STATUS_BOT_FAILURE_MISSING_PRODUCTS: 21,
  ORDER_STATUS_BOT_FAILURE_INSUFFICIENT_FUNDS: 22,
  ORDER_STATUS_BOT_FAILURE_OTHER: 23,
  ORDER_STATUS_IN_MANUAL_PROCESSING: 30,
  ORDER_STATUS_MANUAL_PROCESSING_WAITING_FOR_CLIENT: 31,
  ORDER_STATUS_MANUAL_RETRY_SUCCESS: 41,
  ORDER_STATUS_MANUAL_RETRY_FAIL: 42,
};
