import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "app-sidebar bg-body-secondary shadow",
  "data-bs-theme": "dark"
}
const _hoisted_2 = { class: "sidebar-brand" }
const _hoisted_3 = { class: "sidebar-wrapper" }
const _hoisted_4 = { class: "mt-2" }
const _hoisted_5 = { class: "nav sidebar-menu flex-column" }
const _hoisted_6 = { class: "nav-item" }
const _hoisted_7 = { class: "nav-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/",
        class: "nav-link"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("span", { class: "brand-text fw-light" }, " ThreeBie Backoffice ", -1)
        ])),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("nav", _hoisted_4, [
        _createElementVNode("ul", _hoisted_5, [
          _createElementVNode("li", _hoisted_6, [
            _createVNode(_component_router_link, {
              to: "/orders",
              class: "nav-link"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("i", { class: "nav-icon bi bi-book" }, null, -1),
                _createElementVNode("p", null, "Orders", -1)
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_7, [
            _createVNode(_component_router_link, {
              to: "/products",
              class: "nav-link"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("i", { class: "nav-icon bi bi-bookshelf" }, null, -1),
                _createElementVNode("p", null, "Products", -1)
              ])),
              _: 1
            })
          ])
        ])
      ])
    ])
  ]))
}