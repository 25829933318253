import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-header navbar navbar-expand bg-body" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "navbar-nav" }
const _hoisted_4 = { class: "nav-item d-none d-md-block" }
const _hoisted_5 = { class: "nav-item d-none d-md-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", _hoisted_4, [
          _createVNode(_component_router_link, {
            to: "/",
            class: "nav-link"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Home")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("li", _hoisted_5, [
          _createVNode(_component_router_link, {
            to: "/about",
            class: "nav-link"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("About")
            ])),
            _: 1
          })
        ])
      ])
    ])
  ]))
}