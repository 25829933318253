<template>
    <aside class="app-sidebar bg-body-secondary shadow" data-bs-theme="dark">
        <div class="sidebar-brand">
            <router-link to="/" class="nav-link">
                <span class="brand-text fw-light">
                    ThreeBie Backoffice
                </span>
            </router-link>
        </div>
        <div class="sidebar-wrapper">
            <nav class="mt-2">
                <ul class="nav sidebar-menu flex-column">
                    <li class="nav-item">
                        <router-link to="/orders" class="nav-link">
                            <i class="nav-icon bi bi-book"></i>
                            <p>Orders</p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/products" class="nav-link">
                            <i class="nav-icon bi bi-bookshelf"></i>
                            <p>Products</p>
                        </router-link>
                    </li>
                </ul>
            </nav>
        </div>
    </aside>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Menu',
});
</script>
