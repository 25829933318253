<template>
  <div v-if="isLoading" class="loader-overlay">
    <div class="loader"></div>
  </div>
  <div class="app-content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
          <h3 class="mb-0">Orders</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="app-content">
    <div class="container-fluid">
      <div class="card mb-4">
        <div v-if="errorMessage" class="col-12">
          <div class="callout callout-danger">
            {{ errorMessage }}
          </div>
        </div>
        <div class="card-header">
          <h3 class="card-title">List of orders</h3>
        </div>
        <div class="card-body p-0">
          <table class="table table-sm">
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Order Time</th>
                <th>Status</th>
                <th>Type</th>
                <th>Store</th>
                <th>Delivery Slot</th>
              </tr>
            </thead>
            <tbody v-for="(order, orderIndex) in entries" :key="orderIndex">
              <tr class="align-middle" @click="toggleDetails(order.id)">
                <td>{{ order._id }}</td>
                <td>{{ formatDate(order.createdAt) }}</td>
                <td>
                  <div v-if="editingStatusId === order.id">
                    <select @click.stop class="form-select" v-model="order.status" @change="saveStatus(order)"
                      @blur="cancelEditStatus">
                      <option v-for="(value, key) in statuses" :key="key" :value="value">
                        {{ key.replace('ORDER_STATUS_', '').replace(/_/g, ' ').toLowerCase() }}
                      </option>
                    </select>
                  </div>
                  <div v-else>
                    <span v-html="getStatusText(order.status)" class="float-start"></span>
                    <i @click.stop @click="editStatus(order.id)" class="float-end bi bi-pencil"></i>
                  </div>
                </td>
                <td>{{ getTypeText(order.deliveryType) }}</td>
                <td v-html="order.storeDesc[0]"></td>
                <td>
                  {{ formatDate(order.timeslot.start) }}
                  -
                  {{ formatDate(order.timeslot.end) }}
                </td>
              </tr>
              <tr v-if="expandedOrderId === order.id" class="align-middle">
                <td colspan="6">
                  <table class="table table-sm">
                    <thead>
                      <tr>
                        <th>Item #</th>
                        <th>UPC</th>
                        <th>Description</th>
                        <th>Qty</th>
                        <th>Store</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(product, productIndex) in order.products" :key="productIndex" class="align-middle">
                        <td>{{ productIndex + 1 }}</td>
                        <td>{{ product.chosenProductId }}</td>
                        <td>{{ product.name }}</td>
                        <td>{{ product.quantity }}</td>
                        <td v-html="product.storeDesc[0]"></td>
                        <td>{{ formatToUSD(product.price) }}</td>
                      </tr>
                      <tr class="align-middle">
                        <td colspan="4"></td>
                        <td class="tr"><b>Total:</b></td>
                        <td><b>{{ formatToUSD(order.total) }}</b></td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { getData, postData, patchData } from '@/services/apiService';
import { cache } from '@/services/cache';
import { ORDER_STATUSES } from '@/constants/orderStatuses';
import axios, { AxiosError } from 'axios';

interface UpdateOrderDto {
  status: string;
  comment?: string;
}

export default defineComponent({
  name: 'Orders',
  data() {
    return {
      entries: cache.orders,
      statuses: ORDER_STATUSES,
      isLoading: false,
      errorMessage: '',
      expandedOrderId: null as string | null,
      editingStatusId: null as string | null,
    };
  },
  created() {
    if (cache.orders.length === 0) {
      this.loadEntries();
    }
  },
  methods: {
    async loadEntries() {
      this.isLoading = true;
      try {
        cache.orders = await getData('/orders/orders');
        const productIds: { [key: string]: boolean } = {};
        for (const [orderKey, order] of Object.entries(cache.orders)) {
          order.products.forEach((product: any) => {
            productIds[product.chosenProductId] = true;
          });
        }
        this.loadProducts({ 'productIds': Object.keys(productIds), 'productNames': [] });
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        // this.isLoading = false;
      }
    },
    async loadProducts(productIds: any) {
      // this.isLoading = true;
      try {
        cache.products = await postData('/products/products/getProductsByIds', productIds);
        const storeIds: { [key: string]: boolean } = {};
        for (const [productKey, product] of Object.entries(cache.products)) {
          for (const [orderKey, order] of Object.entries(cache.orders)) {
            storeIds[order.storeId] = true;
            order.products.forEach((orderProduct: any) => {
              storeIds[orderProduct.storeId] = true;

              if (product.id == orderProduct.chosenProductId) {
                orderProduct.name = product.name;
              }
            });
          }
        }
        this.loadStores({ 'storeIds': Object.keys(storeIds) });
      } catch (error) {
        console.error("Error fetching stores:", error);
      } finally {
        // this.isLoading = false;
      }
    },
    async loadStores(storeIds: any) {
      this.isLoading = true;
      try {
        cache.stores = await postData('/localization/stores/getStores', storeIds);
        const storeDesc: { [key: string]: [string, string] } = {};
        for (const [storeKey, store] of Object.entries(cache.stores)) {
          storeDesc[store.id] = [
            store.name,
            'https://maps.google.com/?q=' + store.lat + ',' + store.lon
          ];
        };
        for (const [orderKey, order] of Object.entries(cache.orders)) {
          order.storeDesc = storeDesc[order.storeId];
          order.total = 0;
          order.products.forEach((product: any) => {
            product.storeDesc = storeDesc[product.storeId];
            order.total += product.price;
          });
        };
        this.entries = cache.orders;
      } catch (error) {
        console.error("Error fetching stores:", error);
      } finally {
        this.isLoading = false;
      }
    },
    getStatusText(statusValue: number): string {
      let response = '<div class="status-';

      if (statusValue < 20) {
        response += 'ok';
      } else if (statusValue < 30) {
        response += 'bot';
      } else if (statusValue < 50) {
        response += 'manual';
      } else {
        response += 'ok';
      }

      response += '">';

      for (const [key, value] of Object.entries(this.statuses)) {
        if (value === statusValue) {
          return response + key.replace('ORDER_STATUS_', '').replace(/_/g, ' ').toLowerCase() + '</div>';
        }
      }

      return response + 'unknown</div>';
    },
    getTypeText(type: string): string {
      switch (type) {
        case 'pick_up': {
          return 'PU';
        }
        case 'delivery': {
          return 'Del';
        }
        case 'shipping': {
          return 'Ship';
        }
      }
      return type;
    },
    formatDate(dateString: string) {
      const date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: 'UTC'
      };
      return date.toLocaleDateString('en-US', options);
    },
    formatToUSD(value: number) {
      const formattedValue = value / 100;

      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(formattedValue);
    },
    toggleDetails(orderId: string) {
      if (this.expandedOrderId === orderId) {
        this.expandedOrderId = null;
      } else {
        this.expandedOrderId = orderId;
      }
    },
    editStatus(orderId: string) {
      this.editingStatusId = orderId;
    },
    async saveStatus(order: any) {
      this.isLoading = true;

      try {
        const updateDto: UpdateOrderDto = {
          status: order.status.toString(),
        };

        await patchData(`/orders/orders/${order.id}`, updateDto);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.data && error.response.data.message) {
            this.errorMessage = Array.isArray(error.response.data.message)
              ? error.response.data.message.join(', ')
              : error.response.data.message;
          } else {
            this.errorMessage = 'An error occurred while updating the order. Please try again.';
          }
        } else {
          this.errorMessage = 'An unexpected error occurred. Please try again.';
        }
      } finally {
        this.editingStatusId = null;
        this.isLoading = false;
      }
    },
    cancelEditStatus() {
      this.editingStatusId = null;
    },
  }
});
</script>
