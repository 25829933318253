import axios, { AxiosInstance, AxiosResponse } from 'axios';

interface ApiResponse {
  data: any;
};

const API_URL = process.env.VUE_APP_API_URL as string;
const API_TOKEN_ORDERS = process.env.VUE_APP_TOKEN_ORDERS as string;
const API_TOKEN_PRODUCTS = process.env.VUE_APP_TOKEN_PRODUCTS as string;
const API_TOKEN_SEARCH = process.env.VUE_APP_TOKEN_SEARCH as string;

const apiClient: AxiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(config => {
  if (config.url) {
    if (config.url.startsWith('/orders')) {
      config.headers.Authorization = `Bearer ${API_TOKEN_ORDERS}`;
    } else if (config.url.startsWith('/products')) {
      config.headers.Authorization = `Bearer ${API_TOKEN_PRODUCTS}`;
    } else if (config.url.startsWith('/search')) {
      config.headers.Authorization = `Bearer ${API_TOKEN_SEARCH}`;
    }
  }

  return config;
}, error => {
  return Promise.reject(error);
}
);

export function getData(endpoint: string, searchQuery?: string): Promise<any> {
  const params = searchQuery ? `?name=${searchQuery}` : '';
  return apiClient.get(`${endpoint}${params}`)
    .then((response: AxiosResponse<any>) => {
      let responseData = response.data;

      if (Array.isArray(responseData)) {
        const mappedData = responseData.reduce((acc: any, item: any) => {
          if (item.id !== undefined) {
            acc[item.id] = item;
          }
          return acc;
        }, {});

        return mappedData;
      }

      return responseData;
    })
    .catch(error => {
      console.error("Error fetching data:", error);
      throw error;
    });
}

export function postData(endpoint: string, data: any): Promise<any> {
  return apiClient.post(endpoint, data)
    .then((response: AxiosResponse<any>) => {
      const responseData = response.data;

      if (Array.isArray(responseData)) {
        const mappedData = responseData.reduce((acc: any, item: any) => {
          if (item.id !== undefined) {
            acc[item.id] = item;
          }
          return acc;
        }, {});

        return mappedData;
      }

      return responseData;
    })
    .catch((error) => {
      console.error('Error post data:', error);
      throw error;
    });
}

export function patchData(endpoint: string, data: any): Promise<any> {
  return apiClient.patch(endpoint, data)
    .then((response: AxiosResponse<any>) => response.data)
    .catch((error) => {
      console.error('Error patch data:', error);
      throw error;
    });
}
