<template>
  <div v-if="isLoading" class="loader-overlay">
    <div class="loader"></div>
  </div>
  <div class="app-content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
          <h3 class="mb-0">Products</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="app-content">
    <div class="container-fluid">
      <div class="row g-4">
        <div v-if="errorMessage" class="col-12">
          <div class="callout callout-info">
            {{ errorMessage }}
          </div>
        </div>
        <div class="col-12">
          <div class="card card-danger card-outline mb-4">
            <div class="card-header">
              <div class="card-title">Search products by UPC or name</div>
            </div>
            <div class="card-body">
              <nav class="navbar navbar-expand-lg bg-body-tertiary">
                <form class="d-flex" @submit.prevent="submitForm">
                  <input v-model="searchQuery" class="form-control me-2" placeholder="Search">
                  <button class="btn btn-success" type="submit">Search</button>
                </form>
              </nav>
            </div>
          </div>
        </div>
        <div v-if="products.length" class="col-md-12">
          <div v-for="(product, index) in products" :key="product.id">
            <div class="card card-info card-outline mb-4">
              <div class="card-header">
                <div class="card-title">{{ product.name }}</div>
              </div>
              <div class="card-body">
                <div class="row g-3">
                  <div class="col-md-9">
                    <div class="row g-3">
                      <div class="col-md-4">
                        <label class="form-label">ID</label>
                        <input type="text" class="form-control" :value="product.id" disabled>
                      </div>
                      <div class="col-md-4">
                        <label class="form-label">Size</label>
                        <input type="text" class="form-control" :value="product.size" disabled>
                      </div>
                      <div class="col-md-12">
                        <label class="form-label">Description</label>
                        <textarea class="form-control" disabled rows="20">{{ product.description }}</textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div v-for="(imageUrl, index) in product.imageUrls" :key="index">
                      <a :href="imageUrl" target="_blank" rel="noopener noreferrer">
                        <img :src="imageUrl" alt="Product Image" style="max-width: 200px; margin-bottom: 10px;" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { postData } from '@/services/apiService';
import axios, { AxiosError } from 'axios';

interface Product {
  id: string;
  name: string;
  description: string;
  size: string;
  imageUrls: string[];
}

export default defineComponent({
  name: 'Products',
  data() {
    const searchQuery = ref<string>('');

    return {
      searchQuery,
      products: [],
      errorMessage: '',
      isLoading: false,
    };
  },
  methods: {
    async submitForm() {
      this.isLoading = true;

      try {
        let data;
        const params = {
          productIds: [] as string[],
          productNames: [] as string[],
        }

        if (!isNaN(Number(this.searchQuery))) {
          params.productIds.push(this.searchQuery);
          data = await postData(`/products/products/getProductsByIds`, params);
        } else {
          params.productNames.push(this.searchQuery);
          data = await postData(`/products/products/getProductsByNames`, params);
        }

        this.products = Object.values(data);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.data && error.response.data.message) {
            this.errorMessage = Array.isArray(error.response.data.message)
              ? error.response.data.message.join(', ')
              : error.response.data.message;
          } else {
            this.errorMessage = 'An error occurred while updating the order. Please try again.';
          }
        } else {
          this.errorMessage = 'An unexpected error occurred. Please try again.';
        }
      } finally {
        this.isLoading = false;
      }
    },
  }
});
</script>
