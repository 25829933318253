import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loader-overlay"
}
const _hoisted_2 = { class: "app-content" }
const _hoisted_3 = { class: "container-fluid" }
const _hoisted_4 = { class: "card mb-4" }
const _hoisted_5 = {
  key: 0,
  class: "col-12"
}
const _hoisted_6 = { class: "callout callout-danger" }
const _hoisted_7 = { class: "card-body p-0" }
const _hoisted_8 = { class: "table table-sm" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { key: 0 }
const _hoisted_11 = ["onUpdate:modelValue", "onChange"]
const _hoisted_12 = ["value"]
const _hoisted_13 = { key: 1 }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = {
  key: 0,
  class: "align-middle"
}
const _hoisted_18 = { colspan: "6" }
const _hoisted_19 = { class: "table table-sm" }
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = { class: "align-middle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[3] || (_cache[3] = [
          _createElementVNode("div", { class: "loader" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    _cache[9] || (_cache[9] = _createStaticVNode("<div class=\"app-content-header\"><div class=\"container-fluid\"><div class=\"row\"><div class=\"col-sm-6\"><h3 class=\"mb-0\">Orders</h3></div></div></div></div>", 1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.errorMessage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.errorMessage), 1)
              ]))
            : _createCommentVNode("", true),
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "card-header" }, [
            _createElementVNode("h3", { class: "card-title" }, "List of orders")
          ], -1)),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("table", _hoisted_8, [
              _cache[7] || (_cache[7] = _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, "Order ID"),
                  _createElementVNode("th", null, "Order Time"),
                  _createElementVNode("th", null, "Status"),
                  _createElementVNode("th", null, "Type"),
                  _createElementVNode("th", null, "Store"),
                  _createElementVNode("th", null, "Delivery Slot")
                ])
              ], -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entries, (order, orderIndex) => {
                return (_openBlock(), _createElementBlock("tbody", { key: orderIndex }, [
                  _createElementVNode("tr", {
                    class: "align-middle",
                    onClick: ($event: any) => (_ctx.toggleDetails(order.id))
                  }, [
                    _createElementVNode("td", null, _toDisplayString(order._id), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.formatDate(order.createdAt)), 1),
                    _createElementVNode("td", null, [
                      (_ctx.editingStatusId === order.id)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _withDirectives(_createElementVNode("select", {
                              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
                              class: "form-select",
                              "onUpdate:modelValue": ($event: any) => ((order.status) = $event),
                              onChange: ($event: any) => (_ctx.saveStatus(order)),
                              onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.cancelEditStatus && _ctx.cancelEditStatus(...args)))
                            }, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statuses, (value, key) => {
                                return (_openBlock(), _createElementBlock("option", {
                                  key: key,
                                  value: value
                                }, _toDisplayString(key.replace('ORDER_STATUS_', '').replace(/_/g, ' ').toLowerCase()), 9, _hoisted_12))
                              }), 128))
                            ], 40, _hoisted_11), [
                              [_vModelSelect, order.status]
                            ])
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _createElementVNode("span", {
                              innerHTML: _ctx.getStatusText(order.status),
                              class: "float-start"
                            }, null, 8, _hoisted_14),
                            _createElementVNode("i", {
                              onClick: [
                                _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"])),
                                ($event: any) => (_ctx.editStatus(order.id))
                              ],
                              class: "float-end bi bi-pencil"
                            }, null, 8, _hoisted_15)
                          ]))
                    ]),
                    _createElementVNode("td", null, _toDisplayString(_ctx.getTypeText(order.deliveryType)), 1),
                    _createElementVNode("td", {
                      innerHTML: order.storeDesc[0]
                    }, null, 8, _hoisted_16),
                    _createElementVNode("td", null, _toDisplayString(_ctx.formatDate(order.timeslot.start)) + " - " + _toDisplayString(_ctx.formatDate(order.timeslot.end)), 1)
                  ], 8, _hoisted_9),
                  (_ctx.expandedOrderId === order.id)
                    ? (_openBlock(), _createElementBlock("tr", _hoisted_17, [
                        _createElementVNode("td", _hoisted_18, [
                          _createElementVNode("table", _hoisted_19, [
                            _cache[6] || (_cache[6] = _createElementVNode("thead", null, [
                              _createElementVNode("tr", null, [
                                _createElementVNode("th", null, "Item #"),
                                _createElementVNode("th", null, "UPC"),
                                _createElementVNode("th", null, "Description"),
                                _createElementVNode("th", null, "Qty"),
                                _createElementVNode("th", null, "Store"),
                                _createElementVNode("th", null, "Price")
                              ])
                            ], -1)),
                            _createElementVNode("tbody", null, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(order.products, (product, productIndex) => {
                                return (_openBlock(), _createElementBlock("tr", {
                                  key: productIndex,
                                  class: "align-middle"
                                }, [
                                  _createElementVNode("td", null, _toDisplayString(productIndex + 1), 1),
                                  _createElementVNode("td", null, _toDisplayString(product.chosenProductId), 1),
                                  _createElementVNode("td", null, _toDisplayString(product.name), 1),
                                  _createElementVNode("td", null, _toDisplayString(product.quantity), 1),
                                  _createElementVNode("td", {
                                    innerHTML: product.storeDesc[0]
                                  }, null, 8, _hoisted_20),
                                  _createElementVNode("td", null, _toDisplayString(_ctx.formatToUSD(product.price)), 1)
                                ]))
                              }), 128)),
                              _createElementVNode("tr", _hoisted_21, [
                                _cache[4] || (_cache[4] = _createElementVNode("td", { colspan: "4" }, null, -1)),
                                _cache[5] || (_cache[5] = _createElementVNode("td", { class: "tr" }, [
                                  _createElementVNode("b", null, "Total:")
                                ], -1)),
                                _createElementVNode("td", null, [
                                  _createElementVNode("b", null, _toDisplayString(_ctx.formatToUSD(order.total)), 1)
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ])
          ])
        ])
      ])
    ])
  ], 64))
}