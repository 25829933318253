<template>
  <Nav v-if="showLayout" />
  <Menu v-if="showLayout" />
  <main class="app-main">
    <router-view />
  </main>
  <Footer v-if="showLayout" />
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
// https://github.com/ColorlibHQ/AdminLTE
import { Nav, Menu, Footer } from './components';

export default defineComponent({
  name: 'App',
  components: {
    Nav,
    Menu,
    Footer,
  },
  setup() {
    const route = useRoute();

    const showLayout = computed<boolean>(() => {
      return route.meta.requiresLayout !== false;
    });

    return {
      showLayout,
    };
  },
});
</script>
