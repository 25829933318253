<template>
  <footer class="app-footer">
    Have a nice day :)
  </footer>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Footer',
});
</script>
