import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-content-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "container-fluid" }, [
      _createElementVNode("div", { class: "row" }, [
        _createElementVNode("div", { class: "col-sm-6" }, [
          _createElementVNode("h3", { class: "mb-0" }, "Dashboard")
        ])
      ])
    ], -1)
  ])))
}